@import "@/assets/scss/style.scss";

.wrapper {
    padding: 24px 24px 40px 24px;
    border-radius: 10px;
    border: 2px solid #eee7ff;
    width: 40vw;
    height: 100%;
    margin: 36px auto;
    background-color: $white-two;
    h2 {
        margin-bottom: 24px;
    }

    .switch-wrap {
        margin-bottom: 12px;
        border-bottom: 2px solid #eee7ff;
        padding-bottom: 5px;

        &:last-child {
            border-bottom: none;
        }

        .inner-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 50%;
        }
        h3 {
            font-size: 16px;
            font-weight: 500;
            color: $black;
            margin: 0 6px 0 0;
        }
    }
}

.loading-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);

    .anticon {
        font-size: 40px;
    }
}

@media (max-width: 1620px) {
    .wrapper {
        width: 50vw;
    }
}

@media (max-width: 1400px) {
    .wrapper {
        width: 55vw;
        .switch-wrap {
            .inner-wrap {
                width: 45%;
            }
        }
    }
}

@media (max-width: 1200px) {
    .wrapper {
        width: 60vw;
    }
}

@media (max-width: 1000px) {
    .wrapper {
        width: 70vw;
        .switch-wrap {
            .inner-wrap {
                width: 60%;
            }
        }
    }
}

@media (max-width: 800px) {
    .wrapper {
        .switch-wrap {
            .inner-wrap {
                width: 70%;
            }
        }
    }
}
